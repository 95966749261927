import React from  'react';
import { checkLogged } from '../../lib/login.js';

// components
import AdminNavbar from '../../components/AdminHeader';
import GuestListEditor from '../../components/GuestListEditor';
import { useLocation, useHistory } from "react-router-dom";

import './style.css';
import { AdminSummary } from '../../subviews/AdminSummary/index.js';


const AdminPage = () => {
    let history = useHistory();
    let {hash} = useLocation();

    React.useEffect(()=>{
        checkLogged().then(e=>{
            if (!e)
            history.push('/login');
        });
    },[history]);


    return(
       <div className="main">
           <AdminNavbar />
           {hash === "#guestedit" && <GuestListEditor />}
           {hash === "" && <AdminSummary /> }
       </div>
    )
}

export default AdminPage;