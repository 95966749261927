import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function FeedbackModal({show, message, onClose}) {

  return (
      <Modal show={show} onHide={onClose} style={{zIndex: 9999}}>
        <Modal.Body>
          <p>{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

export default FeedbackModal;