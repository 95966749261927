import React from 'react';
import { Trans } from 'react-i18next';
//import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare, faQuestion, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

export const GuestListTableMobile = ({guestList, tableFilter}) => {
  //const {t} = useTranslation();
  const shareIt = (e) => {
    let idx = e.target.dataset["idx"];
    let person = guestList[idx];

    navigator.share({
      title: `Invitació ${person.name}`,
      text: "Invitació Gemma i Matias",
      url: `https://boda.gemmati.com/u1/${person.short_id}`
    })
  }
  const shareUpdate = (e) => {
    let idx = e.target.dataset["idx"];
    let person = guestList[idx];

    navigator.share({
      title: `Boda Gemma & Matias`,
      text: "Actualització boda",
      url: `https://boda.gemmati.com/u1/${person.short_id}`
    })
  }
  const sharePhotos = (e) => {
    let idx = e.target.dataset["idx"];
    let person = guestList[idx];

    navigator.share({
      title: `Boda Gemma & Matias`,
      text: "Fotos boda",
      url: `https://boda.gemmati.com/photos/${person.short_id}`
    })
  }

	return (
    <table className="GL_table mobile">
    <thead>
      <tr>
        <th></th>
        <th><Trans i18nKey="Guest name"></Trans></th>
        <th><Trans i18nKey="PAX"></Trans></th>
        <th><Trans i18nKey="Share"></Trans></th>
      </tr>
      </thead>
      <tbody>
      {guestList.map((e,idx)=>(
        <tr key={e.id}>
          {e.confirmed ? 
          <td data-id={e.id} data-name="declined"  data-idx={idx}><FontAwesomeIcon size="lg" icon={e.declined ? faThumbsDown : faThumbsUp}   /></td>
          :
          <td data-id={e.id} data-name="confirmed" data-idx={idx}><FontAwesomeIcon size="lg" icon={faQuestion}   /></td>
          }
          <td><p>{e.name}</p></td>
          <td><p>{e.num_guests}</p></td>
          {navigator.share ? <td onClick={sharePhotos} data-id={e.id} data-name="confirmed" data-idx={idx}><FontAwesomeIcon onClick={sharePhotos} size="lg" icon={faShareSquare}   /></td> : null}
        </tr>
        ))}
      </tbody>
    </table>
  )
}