import React from 'react';
import CountDown from 'reactjs-countdown';
import './style.css'
import { Trans } from 'react-i18next';
 
export const Saveday = () => {
    let deadline = "Sat, 28 May 2022 17:00:00 GMT+0200";
    return(
        <div className="count-down-area">
            <div className="count-down-sectionparallax">
                <div className="container">
                    <div className="count-down-item count-down-item2">
                        <div className="row">
                            <div className="col-12 col-md-4 section-area">
                                <div className="section-sub"></div>
                                <h2 className="big"><span><Trans i18nKey="Looking forward">Looking forward for...</Trans></span> <Trans i18nKey="The big day">The big day</Trans></h2>
                            </div>
                            <div className="col-12 col-md-8 clock-area">
                                <div className="count-down-clock">
                                    <CountDown
                                        deadline={deadline}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Saveday;