import {apiCall} from './apiCall';

export async function doLogin(user, password) {
    return apiCall('/api/auth',
        {method: 'POST',
         body: JSON.stringify({"user": user, "password": password})
        }
    );
}
export async function checkLogged() {
    let req = await apiCall('/api/login/check');
    return (req.status === "success");
}