
export async function apiCall(endpoint, data) {
    let base_url = window.location.origin;
    if (process.env.REACT_APP_API_URL) {
        base_url = process.env.REACT_APP_API_URL;
    }
    const response = await fetch(base_url + endpoint, 
        {method: 'GET',
         mode: 'cors',
         credentials: "include",
         headers: {
            'Content-Type': 'application/json'
         },
         ...data
        }
    );
    return response.json();
}