import {apiCall} from './apiCall';

export async function getGuestList() {
    return apiCall(`/api/guests`);
}
export async function addGuest({data}) {
    return apiCall('/api/guest',
        {method: 'POST',
         body: JSON.stringify(data)
        }
    );
}
export async function updateGuests({data}) {
    console.log(data);
    return apiCall('/api/edit_guests',
        {method: 'PUT',
         body: JSON.stringify(data)
        }
    );
}