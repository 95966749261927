import React from  'react';
import { useTranslation } from 'react-i18next';

// components
import { useHistory } from "react-router-dom";
import { doLogin, checkLogged } from '../../lib/login.js';

import './style.css';


const LoginPage = () => {
    const { t } = useTranslation();
    const [error, setError] = React.useState();
    const [data, setData] = React.useState({user: "", pass: ""});
    let history = useHistory();

    React.useEffect(()=>{
        checkLogged().then(e=>{
            if (e)
            history.push('/admin');
        });
    },[history]);

    const changeHandler = (e) => {
        let error = ''

        setData({
            ...data,
            [e.target.name]: e.target.value
        });
        data[e.target.name]= e.target.value;
        setError(error);
    }
    const onLoginResult = response => {
        /*
        Login request has a response.
        200 -> Success
        404 -> Wrong credentials
        */
        console.log("Response:", response)
        console.log(response.status)
        switch (response.status) {
            case "success":
                history.push('/admin')
                break;
            case "error":
                setError(response.error)
                break;
            default: break;
        }
    }
    function handleSubmit(event) {
        event.preventDefault();
        doLogin(data.user, data.pass).then(onLoginResult).catch(e=>{
            console.log("Catched ", e);
        });
    }

    return(
       <div className="login main">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12">
                        <label>{t("Username")}</label>
                        <input type="text" value={data.user} onChange={changeHandler} placeholder={t("Username")} name="user" />
                    </div>
                    <div className="col-12 col-sm-12">
                        <label>{t("Password")}</label>
                        <input type="password" value={data.pass} onChange={changeHandler} placeholder={t("Password")} name="pass" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 text-center">
                        <p className="error">{error ? error : ''}</p>
                    </div>
                    <div className="col-md-6 col-sm-12 offset-md-3 offset-sm-0">
                        <button className="submit" onClick={handleSubmit}>{t("Submit")}</button>
                    </div>
                </div>
            </div>
       </div>
    )
}

export default LoginPage;