import React, {useEffect} from  'react';

// components
import Roadmap from '../../components/roadmap_1';
import Rsvp from '../../components/rsvp_confirm';
import Navbar from '../../components/Navbar';
import Saveday from '../../components/countdown';
import Footer from '../../components/footer';
import Location from '../../components/location';
import Scrollbar from '../../components/scrollbar';
import './style.css';
import {useParams} from "react-router-dom";
import {getInvitationDetails} from '../../lib/invitation.js';
import { useTranslation } from 'react-i18next';

const FirstUpdatePage = () => {
    const { i18n } = useTranslation();
    const {inv_id} = useParams();
    const [is_loading, setIsLoading] = React.useState(true);
    const [user, setUser] = React.useState({});
    
    useEffect(() => {
        setIsLoading(true);
        getInvitationDetails(inv_id).then(e => {
            if (e.error !== undefined)
                return;
            setIsLoading(false);
            if (e.language !== undefined) {
                i18n.changeLanguage(e.language);
            } 
            setUser(e);
        });
    },[inv_id, i18n]);

    if (is_loading) {
        return (<div></div>);
    }

    return(
       <div>
           <Navbar/>
           <Rsvp data={user} inv_id={inv_id}/>
           <Location/>
           <Saveday/>
           <Roadmap/>
           <Footer/>
          <Scrollbar/>
       </div>
    )
}

export default FirstUpdatePage;