import React from 'react'
//import {Link} from 'react-router-dom'
import Sectiontitle from '../section-title'
import image from '../../images/can_pages.jpg'
import { useTranslation, Trans } from 'react-i18next';

import './style.css'

const Location = () => {
    const { t } = useTranslation();
    return(
    <div id="event" className="service-area section-padding">
        <div className="container">
            <Sectiontitle section={t('When & Where')}/>
            <div className="service-area-menu">
                <div className="Ceremony-wrap">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="ceromony-img">
                                <img src={image} alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="ceromony-content">
                                <h3><Trans i18nKey="Ceremony">Ceremony</Trans></h3>
                                <span><Trans i18nKey="May 28th 17pm">May 28th</Trans></span>
                                <span>Espai Can Pagès, 08480 L'Ametlla del Vallès, Barcelona</span>
                                <p><Trans i18nKey="Ceremony description"></Trans></p>
                                <a href="https://g.page/espaicanpages"><Trans i18nKey="See location">See location</Trans></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default Location;