import React, {useEffect} from  'react';

// components
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer';
import PhotoGallery from '../../components/PhotoGallery';
import Scrollbar from '../../components/scrollbar';
import './style.css';
import {useParams} from "react-router-dom";
import {getInvitationDetails} from '../../lib/invitation.js';
import photos_info from '../../lib/photos_info.json';
import { useTranslation } from 'react-i18next';

const PhotosPage = () => {
    const { i18n } = useTranslation();
    const {inv_id} = useParams();
    const [is_loading, setIsLoading] = React.useState(true);
    const [user, setUser] = React.useState({});
    const [pictures, setPictures] = React.useState([]);
    const [thumbs, setThumbnails] = React.useState([]);
    
    useEffect(() => {
        setIsLoading(true);
        getInvitationDetails(inv_id).then(e => {
            if (e.error !== undefined)
                return;
            setIsLoading(false);
            if (e.language !== undefined) {
                i18n.changeLanguage(e.language);
            } 
            setUser(e);
        });

        let new_pictures = [];
        let new_thumbnails = [];
        for (let i = 0; i < photos_info.length; i++) {
            let filename = photos_info[i].filename;
            let tn_w = photos_info[i].width;
            let tn_h = photos_info[i].height;
            let h = photos_info[i].height_full;
            let w = photos_info[i].width_full;
            let new_tn = {
                src: `${process.env.PUBLIC_URL}/fotos/thumbnails/${filename}`,
                height: tn_h,
                width: tn_w
            }
            let new_full = {
                src: `${process.env.PUBLIC_URL}/fotos/full/${filename}`,
                height: h,
                width: w
            }
            new_thumbnails.push(new_tn);
            new_pictures.push(new_full);
        }
        setPictures(new_pictures);
        setThumbnails(new_thumbnails);
    },[inv_id, i18n]);

    if (is_loading) {
        return (<div></div>);
    }

    return(
       <div>
            <Navbar/>
            <PhotoGallery pictures={pictures} thumbnails={thumbs} />
            <Footer/>
       </div>
    )
}

export default PhotosPage;