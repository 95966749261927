import React, {useEffect} from 'react';
//import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { getGuestList } from '../../lib/guests';
import { CardTitle, CardBody, CardText, Card } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';

import './style.css'

export const AdminSummary = () => {
    const [guestList, setGuestList] = React.useState([]);
    const [is_loading, setIsLoading] = React.useState(true);

    useEffect(() => {
        setIsLoading(true);
        getGuestList().then(e => {
            console.log(e);
          setIsLoading(false);
          setGuestList(e);
        });
    },[]);

    let confirmedGuestList = guestList.filter(x=> x.confirmed && !x.declined);
    let unconfirmedGuestList = guestList.filter(x=> !x.confirmed);
    let num_confirmed = confirmedGuestList.reduce((ps, a) => ps + a.num_guests, 0);
    let num_unconfirmed = unconfirmedGuestList.reduce((ps, a) => ps + a.max_guests, 0);
    let num_invited = guestList.reduce((ps, a) => ps + a.max_guests, 0);
    let num_hotel = confirmedGuestList.reduce((ps, a) => a.hotel ? ps + a.num_guests : ps, 0);
    let num_bus = confirmedGuestList.reduce((ps, a) => (a.bus || a.hotel) ? ps + a.num_guests : ps, 0);
    console.log("num_confirmed", num_confirmed);

    if (is_loading) {
        return (<></>)
    }
    return (
        <div className='GL_root'>
            <Container className='cards'>
                <Row>
                    <Col xs="12">
                <Card color="info" outline className="card-singlenumber">
                    <CardBody>
                        <CardTitle tag="h5">
                            Confirmats
                        </CardTitle>
                        <CardText>
                            {num_confirmed}/{num_invited}
                        </CardText>
                    </CardBody>
                </Card>
                </Col>
                <Col xs="6">
                <Card color="info" outline className="card-singlenumber">
                    <CardBody>
                        <CardTitle tag="h5">
                            Pendents
                        </CardTitle>
                        <CardText>
                            {num_unconfirmed}
                        </CardText>
                    </CardBody>
                </Card>
                </Col>
                <Col xs="6">
                <Card color="info" outline className="card-singlenumber">
                    <CardBody>
                        <CardTitle tag="h5">
                            Hotel
                        </CardTitle>
                        <CardText>
                            {num_hotel}
                        </CardText>
                    </CardBody>
                </Card>
                </Col>
                <Col xs="6">
                <Card color="info" outline className="card-singlenumber">
                    <CardBody>
                        <CardTitle tag="h5">
                            Bus
                        </CardTitle>
                        <CardText>
                            {num_bus}
                        </CardText>
                    </CardBody>
                </Card>
                </Col>
                </Row>
            </Container>
        </div>
    )
}