import {apiCall} from './apiCall';

export async function getInvitationDetails(invitation_id) {
    return apiCall(`/api/inv_details?invitation=${invitation_id}`);
}
export async function updateInvitation(data) {
    return apiCall('/api/edit_invite',
        {method: 'PUT',
         body: JSON.stringify(data)
        }
    );
}