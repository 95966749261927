import React from 'react';
import './style.css'
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import {updateInvitation} from '../../lib/invitation.js';
import FeedbackModal from '../FeedbackModal';

const Rsvp = ({data, inv_id}) => {
    const { t } = useTranslation();
    const [error, setError] = React.useState({});
    const [feedback, setFeedback] = React.useState({show: false, message: t("Thank you")});
    const [userData, setUserData] = React.useState({email:"",phone:"",bus:"",hotel:"",guests:"", inv_id: ""});

    const changeHandler = (e) => {
        error[e.target.name] = ''

        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        });
        data[e.target.name]= e.target.value;
        setError({...error});
    }

    React.useEffect(()=>{
        if (data.name === undefined)
            return;

        setUserData({
            email  : data.email || "",
            phone  : data.phone || "",
            bus    : data.bus === null ? "" : data.bus.toString(),
            hotel    : data.hotel === null ? "" : data.hotel.toString(),
            allergies    : data.allergies === null ? "" : data.allergies.toString(),
            guests : data.guests || "",
            inv_id : inv_id || "",
        });
    },[data, inv_id])

    const subimtHandler = (e) => {
        console.log("Accepted");
        e.preventDefault();

        const { phone,
            email,
            guests
            } = userData;
        var error = {};
        let has_error = false;

        if (phone === '') {
            error.phone = "Please enter your phone";
            has_error = true;
        }
        if (email === '') {
            error.email = "Please enter your email";
            has_error = true;
        }
        if (guests === '') {
            error.guests = "Select your number of rsvp";
            has_error = true;
        }


        if (has_error) {
            setError(error);
            return;
        }
        setError({});
        save();
    }

    const declineHandler = (e) => {
        e.preventDefault();
        //console.log("Declined");
        let sd = {
            declined:  true,
            confirmed: true,
            email:     userData.email,
            phone:     userData.phone,
            guests:    parseInt(userData.guests),
            inv_id:    userData.inv_id
        }
        if (userData.bus !== "")
                sd.bus = userData.bus === "true";
        if (userData.hotel !== "")
                sd.hotel = userData.hotel === "true";
        if (userData.allergies !== "")
                sd.allergies = userData.allergies === "true";

        updateInvitation(sd).then(e=>{
            setFeedback({show: true, message: t("Sorry you arent coming")});
        });
    }

    const save = () => {
        //console.log("saving", userData);
        let sd = {
            declined:  false,
            confirmed: true,
            email:     userData.email,
            phone:     userData.phone,
            guests:    parseInt(userData.guests),
            inv_id:    userData.inv_id
        }
        if (userData.bus !== "")
                sd.bus = userData.bus === "true";
        if (userData.hotel !== "")
                sd.hotel = userData.hotel === "true";
        if (userData.allergies !== "")
                sd.allergies = userData.allergies === "true";
        updateInvitation(sd).then(e=>{
            setFeedback({show: true, message: t("Looking forward to see you")});
        });
    }
    const onCloseFeedback = () => {
        setFeedback({...feedback, show: false});
    }

    let guests_options = [];
    for (var i = 1 ; i <= data.max_guests; i++) {
        guests_options.push(i);
    }


    return (
        <div id="rsvp" className="rsvp rsvp-area go-rsvp-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                        <div className="rsvp-wrap">
                            <div className="col-12">
                                <div className="section-title section-title4 text-center">
                                    <h2>Be Our Guest, {data.name}</h2>
                                    <p><Trans i18nKey="Confirm date">Si us plau, confirmeu abans del 28/02/2022</Trans></p>
                                </div>
                            </div>
                            <form onSubmit={subimtHandler}>
                                <div className="contact-form form-style">
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <label>{t("contact_phone")}</label>
                                            <input type="text" value={userData.phone} onChange={changeHandler} placeholder={t("contact_phone")} id="fphone" name="phone" />
                                            <p>{error.phone ? error.phone : ''}</p>
                                        </div>
                                        <div className="col-12  col-sm-6">
                                            <label>{t("contact_mail")}</label>
                                            <input type="text" placeholder={t("contact_mail")} onChange={changeHandler} value={userData.email} id="email" name="email" />
                                            <p>{error.email ? error.email : ''}</p>
                                        </div>
                                        <div className="col col-sm-6 col-12">
                                            <label>{t("Number of guests")}</label>
                                            <select className="form-control" onChange={changeHandler} value={userData.guests} name="guests">
                                                <option value="">{t("Number of guests")}</option>
                                                {guests_options.map(e=>(
                                                    <option key={e} value={e}>{e}</option>
                                                ))}
                                            </select>
                                            <p>{error.ppl ? error.ppl : ''}</p>
                                        </div>
                                        <div className="col col-sm-6 col-12">
                                            <label>{t("Staying in hotel")}</label>
                                            <select className="form-control" onChange={changeHandler} value={userData.hotel} name="hotel">
                                                <option value="">{t("Staying in hotel")}</option>
                                                <option value={true}>Si</option>
                                                <option value={false}>No</option>
                                            </select>
                                            <p>{error.hotel ? error.hotel : ''}</p>
                                        </div>
                                        <div className="col col-sm-6 col-12">
                                            <label>{t("By which means are you coming")}</label>
                                            <select className="form-control" onChange={changeHandler} value={userData.bus} name="bus">
                                                <option value="">{t("By which means are you coming")}</option>
                                                <option value="false">{t("By my own vehicle")}</option>
                                                <option value="true">{t("Bus")}</option>
                                            </select>
                                            <p>{error.bus ? error.bus : ''}</p>
                                        </div>
                                        <div className="col col-sm-6 col-12">
                                            <label>{t("Do you have any alergy")}</label>
                                            <select className="form-control" onChange={changeHandler} value={userData.allergies} name="allergies">
                                                <option value="">{t("Do you have any alergy")}</option>
                                                <option value="true">Si</option>
                                                <option value="false">No</option>
                                            </select>
                                            <p>{error.alergies ? error.alergies : ''}</p>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center disclaimers">
                                        <p><Trans i18nKey="Details disclaimer">Hay muchos detalles por confirmar</Trans></p>
                                        {data.nochild ? <p><Trans i18nKey="Child disclaimer">We are so sorry to ask you to not come with childrens in age 5-15.</Trans></p> : null}
                                    </div>
                                    <div className="col-12 text-center buttons">
                                        <button id="submit" className="submit">{t("Yes I do")}</button>
                                        <button className="decline" onClick={declineHandler}>{t("No I dont")}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <FeedbackModal show={feedback.show} message={feedback.message} onClose={onCloseFeedback} />
        </div>
    )
}

export default Rsvp;