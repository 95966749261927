import React from 'react'
import {Link} from 'react-router-dom'
import './style.css';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

export const MobileMenu = ({full}) => {
    const { i18n } = useTranslation();

    const setLang = (new_lang) => {
        i18n.changeLanguage(new_lang);
    }
    const [isMenuShow, setMenuShow] = React.useState(false);
    const menuHandler = () => {
        setMenuShow(!isMenuShow);
    }

    return (
        <div>
            <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                {/* <div className="clox" onClick={this.menuHandler}>Close Me</div> */}
                <div className="logo2">
                    <h2><Link to='/'>Gemma & Matías</Link></h2>
                </div>
                <ul className="responsivemenu">
                    <li><Link to='#guestlist'><Trans i18nKey="Guest list">Guest list</Trans></Link></li>
                    <li><Link to='#guestedit'><Trans i18nKey="Guest edit">Guest list editor</Trans></Link></li>
                    <li><Link to="#"><Trans i18nKey="Language">Language</Trans></Link>
                        <ul className="submenu submenu2">
                            <li><Link to="#" onClick={e => {setLang("cat")}}>Cat</Link></li>
                            <li><Link to="#" onClick={e => {setLang("es")}}>Cast</Link></li>
                        </ul>
                    </li>
                </ul>
                
            </div>

            <div className="showmenu" onClick={menuHandler}><i className="fa fa-bars" aria-hidden="true"></i></div>         
        </div>
    )
}

export default MobileMenu;