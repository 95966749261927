import React from 'react';

import './style.css'

export const PublicPage = ({full}) => {
    return (

        <div className="footer-area">
            <div className="container">
                <div className="footer-content">
                    <div className="content-sub">
                        <div className="logo">
                            <h2>Gemma<span><i className="fa fa-heart" aria-hidden="true"></i></span>Matías</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}