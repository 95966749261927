import React from "react";
import './style.css'
import { Trans } from 'react-i18next';

export const Gift = () => {
    return (
      <div className="gift-area">
          <div className="container">
                <div className="col-12">
                  <div className="section-title text-center">
                      <h2><Trans i18nKey="Gifts">Gifts</Trans></h2>
                      <p><Trans i18nKey="Gifts text"></Trans></p>
                      <p><strong>ES98 1465 0120 31 1734909129</strong></p>
                  </div>
              </div>
          </div>
      </div>
  );
}

export default Gift;