import React, {useEffect} from 'react';
//import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { getGuestList, updateGuests } from '../../lib/guests';
import AddGuestModal from '../AddGuestModal';
import { GuestListTable } from './table';
import { GuestListTableMobile } from './table_mobile';
import { useMediaQuery } from 'react-responsive';
import Form from 'react-bootstrap/Form';

import './style.css'

export const GuestListEditor = () => {
    const [guestList, setGuestList] = React.useState([]);
    const [toUpdate, setToUpdate] = React.useState({});
    const [is_loading, setIsLoading] = React.useState(true);
    const [showNewGuest, setNewGuest] = React.useState(false);
    const [filters, setFilters] = React.useState({show_choices: false});
    const {t} = useTranslation();
    const is_mobile = useMediaQuery({ query: '(max-width: 992px)' });

    useEffect(() => {
        setIsLoading(true);
        getGuestList().then(e => {
          setIsLoading(false);
          setGuestList(e);
        });
    },[]);

    const onAddNew = (reload) => {
        if (reload) {
            getGuestList().then(setGuestList);
        };
        setNewGuest(false);
    };

    const onChange = (e) => {
        let guest_id = e.target.dataset["id"];
        let idx      = e.target.dataset["idx"];
        let new_val  = e.target.value;
        if (e.target.type === "checkbox") {
            new_val = e.target.checked;
        }
        setToUpdate({...toUpdate, [guest_id]: {...toUpdate[guest_id], [e.target.name]: new_val}});
        guestList[idx][e.target.name] = new_val;
        setGuestList(guestList);
    };
    const onChangeIcon = (e) => {
        let target = e.target;
        if (target.tagName !== "td") {
            target = target.closest("td");
        }
        let guest_id = target.dataset["id"];
        let idx      = target.dataset["idx"];
        let name     = target.dataset["name"];
        let curr_val = guestList[idx][name] || false;
        let new_val  = !curr_val;
        if (name === "language") {
            new_val = curr_val === "es" ? "cat" : "es";
        }

        setToUpdate({...toUpdate, [guest_id]: {...toUpdate[guest_id], [name]: new_val}});
        guestList[idx][name] = new_val;
        setGuestList(guestList);
    };

    const onSave = () => {
        updateGuests({data: toUpdate}).then(e=>{setToUpdate({})});
    }

    if (is_loading) {
        return (<div></div>);
    }

    const changeFilterBool = (e) => {
        let element_name = e.target.name;
        setFilters({...filters, [element_name]: e.target.checked});
    }

    return(
        <div className="GL_root">
            {is_mobile ? null : 
                <div className="GL_selector">
              <Form.Check type="checkbox" checked={filters.show_choices}  onChange={changeFilterBool} name="show_choices" label={t("show_choices")}/>
            </div>
            }
            <div className="GL_header">
              {is_mobile ? 
                    <GuestListTableMobile guestList={guestList} />
                    :
                    <GuestListTable guestList={guestList} onChange={onChange} onChangeIcon={onChangeIcon} filters={filters} />
                }
              <input type="button" onClick={onSave} value={t("send")} />
              <input type="button" onClick={()=>{setNewGuest(true)}} value={t("Add new guest")} />
            </div>
            <AddGuestModal show={showNewGuest} onClose={onAddNew}/>
        </div>
    );
}

export default GuestListEditor;