import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  es: {
    translation: {
      "Start": "Inicio",
      "Us": "Nosotros",
      "Ceremony": "Ceremonia",
      "Stay": "Alojamiento",
      "Transportation": "Transporte",
      "Language": "Idioma",
      "Save the date": "Guardáos la fecha",
      "Marrying": "Nos casamos",
      "May 28th": "28 de Mayo 2022",
      "May 28th 17pm": "28 Mayo 2022, 17:00",
      "Confirm date": "Por favor, confirmad antes del 15/03/2022",
      "contact_phone": "Teléfono de contacto*",
      "contact_mail": "Email de contacto*",
      "Number of guests": "Asistentes*",
      "Staying in hotel": "¿Os quedaréis en el hotel?",
      "By which means are you coming": "¿Cómo vendréis?",
      "By my own vehicle": "Transporte propio",
      "Bus": "Autocar",
      "Do you have any alergy": "¿Tenéis alguna alergia o intolerancia?",
      "Submit": "Enviar",
      "Looking forward": "Ya queda menos para...",
      "The big day": "El gran día",
      "The couple": "Los novios",
      "Gemma introduction": "Hola, soy Gemma.<br />Tengo 28 años y soy muy alegre, excepto cuando me enfado, entonces tengo muy mal genio. <br />Me gusta muchísimo el verano porque soy muy fredolica, pero también disfruto mucho la Navidad.<br /> En general, me deshago de amor por qualquier animalito que sea un poco adorable, como los perritos, las nútrias o los ratones. Como muchísimo fuet, que compenso con deporte en casa. Estudié psicología y, por suerte, es a lo que me dedico.<br /> Estoy un poco nerviosa por la boda, pero cuando me sienta acompañada por todos vosotros, ¡seguro que la felicidad lo compensará!",
      "Groom introduction": "Hola, soy el Novio.<br />Bromista, paciente, apasionado de la tecnología, el motor...<br /> Me gusta el deporte, pero por lo general me da pereza. Esquío cuando es temporada, y en verano me gusta más dar vueltas con la moto, ir al pueblo o a bucear.<br />Me dedico a la informática, por vocación.<br /> También me gusta la cerveza, crear empresas, el bricolage o la electrónica.<br />¡Tengo muchas ganas de que llegue el gran día y que nos lo pasemos genial! Y de la luna de miel, que mientras estéis trabajando, nosotros estaremos por ahí de relax.",
      "Gifts": "Regalos",
      "Gifts text": "Si nos queréis hacer un regalo, cosa que os agradecemos muchísimo, os pediríamos que fuese una transferencia al siguiente número de cuenta.<br />En el piso no tenemos sitio ni para una tostadora, y cualquier objeto de ese tamaño sería más un problema que otra cosa.<br />La boda está pagada y no queremos que por tener que aportar dinero dejéis de asistir. El precio del cubierto lo podéis encontrar en la web del sitio donde haremos la ceremonia.",
      "Confirmed": "Confirmado",
      "Guest name": "Nombre",
      "Guest alias": "Alias",
      "Guest number": "Número",
      "Guest max number": "Número max",
      "Guest phone": "Teléfono",
      "Guest group": "Grupo",
      "Guest bus": "Bus",
      "Choose bus": "Escoge bus",
      "Guest hotel": "Hotel",
      "Choose hotel": "Escoge hotel",
      "Guest list": "Listado de invitados",
      "Guest edit": "Gestionar invitados",
      "Add new guest": "Añadir invitado",
      "send": "Enviar",
      "Close": "Cerrar",
      "Ask nochild": "Disclaimer niños",
      "Yes I do": "Sí, quiero!",
      "No I dont": "No vendremos",
      "Details disclaimer": "Aun quedan muchas cosas por concretar. Os enviaremos más información en cuanto la tengamos, por eso necesitamos vuestro contacto :)",
      "Child disclaimer": "Dado que será una fiesta para adultos, os tenemos que pedir que no acudan niños de entre 4 y 15 años.",
      "Looking forward to see you": "Muchas gracias, esperamos veros pronto!",
      "Sorry you arent coming": "Gracias por avisar, esperamos veros pronto",
      "Username": "Usuario",
      "Password": "Contraseña",
      "View": "Ver",
      "When & Where": "Fecha y lugar",
      "Ceremony description": "Todo el evento será en el mismo espacio. Constará de bienvenida, la ceremonia en sí, un aperitivo, cena y baile.",
      "Location": "Ubicación",
      "show_choices": "Mostrar opciones",
      "final_festa": "fin de fiesta",
      "Roadmap": "Hoja de ruta",
    }
  },
  cat: {
    translation: {
      "Start": "Inici",
      "Us": "Nosaltres",
      "Ceremony": "Cerimonia",
      "Stay": "Allotjament",
      "Transportation": "Transport",
      "Language": "Idioma",
      "Save the date": "Guardeu-vos la data",
      "Marrying": "Ens casem",
      "May 28th": "28 Maig 2022",
      "May 28th 17pm": "28 Maig 2022, 17:00",
      "Confirm date": "Si us plau, confirmeu abans del 15/03/2022",
      "contact_phone": "Telèfon de contacte*",
      "contact_mail": "Email de contacte*",
      "Number of guests": "Numero d'assistents*",
      "Staying in hotel": "Us quedareu a dormir a l'hotel?",
      "By which means are you coming": "Com vindreu?",
      "By my own vehicle": "Transport propi",
      "Bus": "Autocar",
      "Do you have any alergy": "Teniu al·lèrgies o intoleràncies?",
      "Submit": "Envia",
      "Looking forward": "Ja queda menys per...",
      "The big day": "El gran dia",
      "The couple": "Els nuvis",
      "Gemma introduction": "Hola, sóc la Gemma. Tinc 28 anys i sóc força alegre menys quan m’enfado, llavors tinc bastant mal geni. M’agrada moltíssim l’estiu perquè sóc molt fredolica, però també gaudeixo molt el Nadal. En general, em desfaig d’amor per qualsevol animalet que sigui una mica adorable, com els gossets, les llúdrigues o els ratolins. Menjo moltíssim fuet, que balancejo amb molt d’esport a casa. Vaig estudiar psicologia i, per sort, m’hi dedico. Estic una mica nerviosa per al casament, però quan em senti acompanyada per tots vosaltres segur que la felicitat ho compensarà tot!",
      "Groom introduction": "Hola, sóc el Nuvi.<br />Bromista, pacient, apassionat de la tecnologia i el motor.<br /> M'agrada l'esport, però generalment em fa mandra.<br />Vaig a esquiar a l'hivern, i a l'estiu m'agrada més anar a fer viatges amb la moto, anar al poble o a fer submarinisme.<br />Em dedico a la informàtica.<br /> També m'agrada la cervesa, crear empreses, el bricolatge o l'electrònica.<br />Tinc moltes ganes de que arribi el gran dia i que ens ho passem genial! I de la lluna de mel, que mentres vosaltres esteu treballant, nosaltres marxarem de relax.",
      "Gifts": "Regals",
      "Gifts text": "Si ens voleu fer un regal, cosa que us agraïm moltíssim, us demanem que sigui una transferència al següent número de compte.<br />Al pis no ens hi cap ni una torradora, i qualsevol objecte d'aquesta mida sería més un problema que no pas un obsequi.<br />La boda està pagada, i no volem que no vingueu per motius econòmics. El preu del cobert el podeu trobar a la web del espai on farem la cerimònia.",
      "Confirmed": "Confirmat",
      "Guest name": "Nom",
      "Guest alias": "Alias",
      "Guest number": "Número",
      "Guest max number": "Número max",
      "Guest phone": "Telèfon",
      "Guest group": "Grup",
      "Guest bus": "Bus",
      "Choose bus": "Escull bus",
      "Guest hotel": "Hotel",
      "Choose hotel": "Escull hotel",
      "Guest list": "Llistat de convidats",
      "Guest edit": "Gestionar convidats",
      "Add new guest": "Afegir convidat",
      "send": "Envia",
      "Close": "Tancar",
      "Ask nochild": "Avis nens",
      "Yes I do": "Sí, vull!",
      "No I dont": "No assistirem",
      "Child disclaimer": "Sentim haver de demanar-vos que no vingueu amb nens entre 4 i 15 anys, donat que serà una festa per adults",
      "Details disclaimer": "Encara hi ha algunes coses per tancar. Us enviarem més informació quan la tinguem, per aquest motiu necessitem el vostre contacte :)",
      "Looking forward to see you": "Gracies, esperem veure'ns aviat!",
      "Username": "Usuari",
      "Password": "Contrassenya",
      "View": "Veure",
      "When & Where": "Lloc i hora",
      "Ceremony description": "Tot l'esdeveniment serà al mateix espai. Constarà de benvinguda, la cerimònia en si, un aperitiu, sopar i ball",
      "Location": "Ubicació",
      "show_choices": "Escollir coses",
      "final_festa": "fins al final de la festa",
      "Roadmap": "Full de ruta",
      "Bus_sagrera_title": "Autobús Sagrera",
      "Bus_sagrera_desc": "L’autocar sortirà de La Sagrera, a la Meridiana davant de la Granier.",
      "Bus_hotel_title": "Autobús Hotel",
      "Bus_hotel_desc": "L’autocar us recollirà per la porta de l’hotel.",
      "Bus_ripo_title": "Autobús Ripollet",
      "Bus_ripo_desc": "L’autocar sortirà de davant de l’Hotel Ibis.",
      "schedule_opening_title": "Obertura de portes",
      "schedule_opening_desc": "Comença el casament!! Tothom a la porta a les 17:00h, on el nuvi us rebrà amb uns refrescos de benvinguda i, poc a poc, anirem cap a l’espai de la cerimònia. ",
      "schedule_ceremony_title": "Inici cerimònia",
      "schedule_ceremony_desc": "Esperarem pacientment a la núvia per al moment més emotiu de la vetllada. ",
      "schedule_food_title": "Aperitiu",
      "schedule_food_desc": "Per anar fent boca, hi haurà un aperitiu a l’aire lliure. Nosaltres aprofitarem per fer-nos algunes fotos!",
      "schedule_dinner_title": "Entrada al saló",
      "schedule_dinner_desc": "Més menjar i beure! Entrarem al saló a sopar. Si algú surt amb gana, ja demanarem que ens portin pizzes! ",
      "schedule_dance_title": "Ball i festa",
      "schedule_dance_desc": "Fins que el cos digui prou. Si aguanteu prou i encara teniu gana, hi haurà ressopó",
      "schedule_return_bus_title": "Tornada primer autocar",
      "schedule_return_bus_desc": "Per si us voleu recollir a una hora decent. Para a tots els punts de recollida.",
      "schedule_return_last_bus_title": "Tornada últim autocar",
      "schedule_return_last_bus_desc": "Para a tots els punts de recollida.",
      "conf_youllcome": "Vindreu",
      "conf_peoplein": "persones en",
      "conf_stayhotel": "Us quedareu a l'Hotel",
      "conf_seeinvite": "Veure invitació",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "es", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;