import React from 'react'
import {Link} from 'react-router-dom'
import bus_hotel from './bus_hotel.jpg'
import bus_ripo from './bus_ripo.jpg'
import bus_sagrera from './bus_sagrera.jpg'
import bus_2 from './bus_2.jpg'
import door_opening_img from './door_opening.jpg'
import ceremony_img from './can_pages_ceremony.jpg'
import dinner_img from './can_pages_dinner.jpg'
import party_img from './party.jpg'
import picapica_img from './can_pages_picapica.jpeg'
import './style.css'
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';


const Roadmap = () =>{
    const { t } = useTranslation();
    return(
        <div id="story" className="story-area section-padding">
            <div className="container">
                <div className="col-l2">
                    <div className="section-title text-center">
                        <h2><Trans i18nKey="Roadmap">Roadmap</Trans></h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="story clearfix">
                            <div className="story-top"></div>
                            <div className="content-wrapper">
                                <div className="item">
                                    <div className="story-icon">
                                        <span className="flaticon-birds-in-love"></span>
                                    </div>
                                    <div className="story-content">
                                        <div className="image-wrap">
                                            <div className="single-image">
                                                <div>
                                                    <img src={bus_sagrera} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <h2><Trans i18nKey="Bus_sagrera_title">Autobús Sagrera</Trans></h2>
                                        <span className="date">28/5/2022, 15:45h</span>
                                        <p><Trans i18nKey="Bus_sagrera_desc">El autobús saldrá de delante de la parada de metro de Sagrera, en la Meridiana enfrente de Granier.</Trans></p>
                                        <div className="story-button">
                                            <a className="theme-btn" href="https://goo.gl/maps/6j7L5igCKdFFBdXA8"  title={t("Location")}><Trans i18nKey="Location"></Trans></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="story-icon">
                                        <span className="flaticon-birds-in-love"></span>
                                    </div>
                                    <div className="story-content">
                                        <div className="image-wrap">
                                            <div className="single-image">
                                                <div>
                                                    <img src={bus_hotel} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <h2><Trans i18nKey="Bus_hotel_title">Autobús Hotel</Trans></h2>
                                        <span className="date">28/5/2022, 16:15h</span>
                                        <p><Trans i18nKey="Bus_hotel_desc">El autobús os recogerá en la puerta del hotel.</Trans></p>
                                        <div className="story-button">
                                            <a className="theme-btn" href="https://goo.gl/maps/Q6cEaAiriUG7JPHg8"  title={t("Location")}><Trans i18nKey="Location"></Trans></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="story-icon">
                                        <span className="flaticon-birds-in-love"></span>
                                    </div>
                                    <div className="story-content">
                                        <div className="image-wrap">
                                            <div className="single-image">
                                                <div>
                                                    <img src={bus_ripo} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <h2><Trans i18nKey="Bus_ripo_title">Autobús Ripollet</Trans></h2>
                                        <span className="date">28/5/2022, 16:15h</span>
                                        <p><Trans i18nKey="Bus_ripo_desc">El autobús os recogerá enfrente del Hotel Ibis.</Trans></p>
                                        <div className="story-button">
                                            <a className="theme-btn" href="https://goo.gl/maps/2nocdPnr3duNFhS17"  title={t("Location")}><Trans i18nKey="Location"></Trans></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="story-icon">
                                        <span className="flaticon-birds-in-love"></span>
                                    </div>
                                    <div className="story-content">
                                        <div className="image-wrap">
                                            <div className="single-image">
                                                <div>
                                                    <img src={door_opening_img} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <h2><Trans i18nKey="schedule_opening_title">Apertura de puertas</Trans></h2>
                                        <span className="date">28/5/2022, 17:00h</span>
                                        <p><Trans i18nKey="schedule_opening_desc">¡Empieza la boda! Todo el mundo en la puerta a las 17:00. El novio os recibirá con unos refrescos y bajaremos al espacio de la ceremonia.</Trans></p>
                                        <div className="story-button">
                                            <Link className="theme-btn" to="#location"  title={t("Location")}><Trans i18nKey="Location"></Trans></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="story-icon">
                                        <span className="flaticon-birds-in-love"></span>
                                    </div>
                                    <div className="story-content">
                                        <div className="image-wrap">
                                            <div className="single-image">
                                                <div>
                                                    <img src={ceremony_img} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <h2><Trans i18nKey="schedule_ceremony_title">Inicio de la ceremonia</Trans></h2>
                                        <span className="date">28/5/2022, 17:30h</span>
                                        <p><Trans i18nKey="schedule_ceremony_desc">Esperaremos pacientemente a la novia y al acabar ya estaremos casados.</Trans></p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="story-icon">
                                        <span className="flaticon-birds-in-love"></span>
                                    </div>
                                    <div className="story-content">
                                        <div className="image-wrap">
                                            <div className="single-image">
                                                <div>
                                                    <img src={picapica_img} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <h2><Trans i18nKey="schedule_food_title">Aperitivo</Trans></h2>
                                        <span className="date">28/5/2022, 18:30h</span>
                                        <p><Trans i18nKey="schedule_food_desc">Mientras las novios estemos haciéndonos las fotos, vosotros a comer y a beber. Guardadnos algo.</Trans></p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="story-icon">
                                        <span className="flaticon-birds-in-love"></span>
                                    </div>
                                    <div className="story-content">
                                        <div className="image-wrap">
                                            <div className="single-image">
                                                <div>
                                                    <img src={dinner_img} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <h2><Trans i18nKey="schedule_dinner_title">Entrada al salón</Trans></h2>
                                        <span className="date">28/5/2022, 20:30h</span>
                                        <p><Trans i18nKey="schedule_dinner_desc">¡Más comida y más bebida! Dos platos, café, cava...</Trans></p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="story-icon">
                                        <span className="flaticon-birds-in-love"></span>
                                    </div>
                                    <div className="story-content">
                                        <div className="image-wrap">
                                            <div className="single-image">
                                                <div>
                                                    <img src={party_img} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <h2><Trans i18nKey="schedule_dance_title">Baile y fiesta</Trans></h2>
                                        <span className="date">28/5/2022, 23:30h</span>
                                        <p><Trans i18nKey="schedule_dance_desc">Hasta que el cuerpo aguante. Primero haremos el baile nupcial y luego ya os dejamos la pista al resto. Habrá recompensa para los que aguanten en forma de recena.</Trans></p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="story-icon">
                                        <span className="flaticon-birds-in-love"></span>
                                    </div>
                                    <div className="story-content">
                                        <div className="image-wrap">
                                            <div className="single-image">
                                                <div>
                                                    <img src={bus_2} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <h2><Trans i18nKey="schedule_return_bus_title">Vuelta primer bus</Trans></h2>
                                        <span className="date">29/5/2022, 1:00h</span>
                                        <p><Trans i18nKey="schedule_return_bus_desc">Por si el cuerpo no ha aguantado tanto.</Trans></p>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="story-icon">
                                        <span className="flaticon-birds-in-love"></span>
                                    </div>
                                    <div className="story-content">
                                        <div className="image-wrap">
                                            <div className="single-image">
                                                <div>
                                                    <img src={bus_2} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <h2><Trans i18nKey="schedule_return_last_bus_title">Vuelta segundo bus</Trans></h2>
                                        <span className="date">29/5/2022, {t("final_festa")}</span>
                                        <p><Trans i18nKey="schedule_return_last_bus_desc">Para l@s valientes que aguanten hasta el final. Parada en todos los puntos de recogida.</Trans></p>
                                    </div>
                                </div>
                            </div>
                            <div className="story-bottom"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Roadmap;