import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import {guest_groups} from '../../lib/constants';
import { addGuest } from '../../lib/guests';

function AddGuestModal({show, onClose}) {
  const {t} = useTranslation();
  const [data, setData] = useState({can_bus: true, can_hotel: true, bus: false, hotel: false, num_guests: 0, max_guests: 1, nochild: false});
  const [error, setError] = useState({});

  const changeHandler = (e) => {
      error[e.target.name] = ''

      setData({
          ...data,
          [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
      });
      setError({...error});
      console.log(data);
  }


  const handleClose = () => {
    setData({});
    onClose(false);
  };
  const handleSubmit = () => {
    addGuest({data: data}).then(() =>  {
      setData({});
      onClose(true);
    });
  };

  return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><Trans i18nKey="Add new guest"></Trans></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group className="mb-3">
            <Form.Label><Trans i18nKey="Guest name"></Trans></Form.Label>
            <Form.Control type="text" value={data.name}  onChange={changeHandler} placeholder={t("Guest name")} name="name" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label><Trans i18nKey="Guest alias"></Trans></Form.Label>
            <Form.Control type="text" value={data.alias}  onChange={changeHandler} placeholder={t("Guest alias")} name="alias" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label><Trans i18nKey="Guest phone"></Trans></Form.Label>
            <Form.Control type="text" value={data.phone}  onChange={changeHandler} placeholder={t("Guest phone")} name="phone" />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label><Trans i18nKey="Guest group"></Trans></Form.Label>
            <Form.Control as="select" name="group" value={data.group} onChange={changeHandler}>
                {guest_groups.map(e=>(
                    <option key={e} value={e}>{e}</option>
                ))}
            </Form.Control>
          </Form.Group>
          <Row>
            <Col>
            <Form.Group className="mb-3">
              <Form.Label><Trans i18nKey="Guest number"></Trans></Form.Label>
              <Form.Control type="text" value={data.num_guests}  onChange={changeHandler} placeholder={t("Guest number")} name="num_guests" />
            </Form.Group>
            </Col>
            <Col>
            <Form.Group className="mb-3">
              <Form.Label><Trans i18nKey="Guest max number"></Trans></Form.Label>
              <Form.Control type="text" value={data.max_guests}  onChange={changeHandler} placeholder={t("Guest max number")} name="max_guests" />
            </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
            <Form.Group className="mb-3">
              <Form.Check type="checkbox" checked={data.can_bus}  onChange={changeHandler} name="can_bus" label={t("Choose bus")}/>
            </Form.Group>
            </Col>
            <Col>
            <Form.Group className="mb-3">
              <Form.Check type="checkbox" checked={data.bus}  onChange={changeHandler} name="bus" label={t("Guest bus")}/>
            </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
            <Form.Group className="mb-3">
              <Form.Check type="checkbox" checked={data.can_hotel}  onChange={changeHandler} name="can_hotel" label={t("Choose hotel")}/>
            </Form.Group>
            </Col>
            <Col>
            <Form.Group className="mb-3">
              <Form.Check type="checkbox" checked={data.hotel}  onChange={changeHandler} name="hotel" label={t("Guest hotel")}/>
            </Form.Group>
            </Col>
          </Row>
            <Form.Group className="mb-3">
              <Form.Check type="checkbox" checked={data.nochild}  onChange={changeHandler} name="nochild" label={t("Ask nochild")}/>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <Trans i18nKey="Close"></Trans>
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            <Trans i18nKey="send"></Trans>
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

export default AddGuestModal;