import React from 'react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import PhotoAlbum from "react-photo-album";

const PhotoGallery = ({pictures, thumbnails}) => {
    const [lightbox_idx, setFSP] = React.useState(-1);

    return (
    	<>
           <PhotoAlbum layout="rows" photos={thumbnails} onClick={(event, photo, index) => setFSP(index)}/>
           <Lightbox
            open = {lightbox_idx >= 0}
            slides={pictures}
            index={lightbox_idx}
            close={() => setFSP(-1)}
            animation={{fade: 100, swipe:250}}
            />
      </>
    )
}
export default PhotoGallery;