import React from 'react'
//import AnchorLink from 'react-anchor-link-smooth-scroll'
import {Link} from 'react-router-dom'
import './style.css';
//import { Trans } from 'react-i18next';

export const MobileMenu = ({full}) => {
    const [isMenuShow, setMenuShow] = React.useState(false);
    const menuHandler = () => {
        setMenuShow(!isMenuShow);
    }

    return (
        <div>
            <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                {/* <div className="clox" onClick={this.menuHandler}>Close Me</div> */}
                <div className="logo2">
                    <h2><Link to='#'>Gemma & Matías</Link></h2>
                </div>
                {/*
                <ul className="responsivemenu">
                    <li><Link to='/'><Trans i18nKey="Start">Start</Trans></Link></li>
                    <li><AnchorLink href='#couple'><Trans i18nKey="Us">Nosaltres</Trans></AnchorLink></li>
                    <li><AnchorLink href='#rsvp'><Trans i18nKey="Gifts">Regals</Trans></AnchorLink></li>
                </ul>
                */}
                
            </div>

            <div className="showmenu" onClick={menuHandler}><i className="fa fa-bars" aria-hidden="true"></i></div>         
        </div>
    )
}

export default MobileMenu;