import React from 'react';
import {Link} from 'react-router-dom';
import MobileMenu from './mobile';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import './style.css'

export const Header = () => {
    const { i18n } = useTranslation();

    const setLang = (new_lang) => {
        i18n.changeLanguage(new_lang);
    }

    return(

        <div className="Header_root">
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-10">
                            <div className="logo">
                                <h2>Gemma<span><i className="fa fa-heart" aria-hidden="true"></i></span>Matías</h2>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="header-menu d-lg-block d-none">
                                <ul className="mobail-menu d-flex">
                                    <li><Link to='/admin'><Trans i18nKey="Summary">Summary</Trans></Link></li>
                                    <li><Link to='#guestlist'><Trans i18nKey="Guest list">Guest list</Trans></Link></li>
                                    <li><Link to='#guestedit'><Trans i18nKey="Guest edit">Guest list editor</Trans></Link></li>
                                    <li><Link to="#"><Trans i18nKey="Language">Language</Trans></Link>
                                        <ul className="submenu submenu2">
                                            <li><Link to="#" onClick={e => {setLang("cat")}}>Cat</Link></li>
                                            <li><Link to="#" onClick={e => {setLang("es")}}>Cast</Link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-2">
                            <MobileMenu/>
                        </div>
                    </div>
                </div>
             </div>
      </div>
        
    )
}

export function AdminNavbar() {
  const [scroll, setScroll] = React.useState(0);

  const handleScroll = () => setScroll(document.documentElement.scrollTop);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const className = scroll > 80 ? "fixed-navbar animated fadeInDown active" : "fixed-navbar";

  return (
    <div className={className}>
        <Header/>
    </div>
  ); 
}

export default AdminNavbar;
