import React from 'react';
import './style.css'
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Rsvp = ({data, inv_id}) => {
    const { t } = useTranslation();
    const [userData, setUserData] = React.useState({email:"",phone:"",bus:"",hotel:"",guests:"", inv_id: ""});

    React.useEffect(()=>{
        if (data.name === undefined)
            return;

        setUserData({
            email  : data.email || "",
            phone  : data.phone || "",
            bus    : data.bus === null ? false : data.bus,
            hotel    : data.hotel === null ? false : data.hotel,
            allergies    : data.allergies === null ? "" : data.allergies.toString(),
            guests : data.guests || "",
            inv_id : inv_id || "",
        });
    },[data, inv_id])

    return (
        <div id="rsvp" className="rsvp rsvp-area go-rsvp-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                        <div className="rsvp-wrap">
                            <div className="col-12">
                                <div className="section-title section-title4 text-center">
                                    <h2>{data.name}</h2>
                                    <p><Trans i18nKey="conf_youllcome">Vendréis</Trans> {userData.guests} <Trans i18nKey="conf_peoplein">personas en </Trans> {t(userData.bus ? "Bus" : "By my own vehicle")}</p>
                                    {userData.hotel ? <p><Trans i18nKey="conf_stayhotel">Os quedaréis en el Hotel</Trans></p> : <></>}
                                </div>
                            </div>
                            <form>
                                <div className="contact-form form-style">
                                    <div className="col-12 text-center buttons">
                                        <Link to={`/i/${userData.inv_id}`}><Trans i18nKey="conf_seeinvite">Ver invitación</Trans></Link>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Rsvp;