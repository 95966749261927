import React from 'react';
import { Trans } from 'react-i18next';
//import { useTranslation } from 'react-i18next';
import { guest_groups } from '../../lib/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faChessRook, faCat, faTimesCircle, faQuestion, faThumbsUp, faThumbsDown, faChild, faShareSquare, faEye, faToiletPaperSlash } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

export const GuestListTable = ({onChangeIcon, onChange, guestList, filters}) => {
  //const {t} = useTranslation();
  const shareIt = (e) => {
    let idx = e.target.dataset["idx"];
    let person = guestList[idx];

    navigator.share({
      title: `Invitació ${person.name}`,
      text: "Invitació Gemma i Matias",
      url: `https://boda.gemmati.com/i/${person.short_id}`
    })
  }

	return (
      <table className="GL_table">
      <thead>
        <tr>
          <th><Trans i18nKey="Confirmed"></Trans></th>
          <th><Trans i18nKey="Guest name"></Trans></th>
          <th><Trans i18nKey="Guest alias"></Trans></th>
          <th><Trans i18nKey="Guest phone"></Trans></th>
          <th><Trans i18nKey="Guest group"></Trans></th>
          <th><Trans i18nKey="Guest number"></Trans></th>
          <th><Trans i18nKey="Guest max number"></Trans></th>
          <th><Trans i18nKey="Guest bus"></Trans></th> 
          {filters.show_choices ?
          <th><Trans i18nKey="Choose bus"></Trans></th>
          : null}
          <th><Trans i18nKey="Guest hotel"></Trans></th>
          {filters.show_choices ?
          <th><Trans i18nKey="Choose hotel"></Trans></th>
          : null}
          <th><Trans i18nKey="Language"></Trans></th>
          {filters.show_choices ?
          <th><Trans i18nKey="Ask nochild"></Trans></th>
          : null}
          <th><Trans i18nKey={navigator.share ?"Share" : "View"}></Trans></th>
        </tr>
        </thead>
        <tbody>
        {guestList.map((e,idx)=>(
          <tr key={e.id}>
            {e.confirmed ? 
            <td data-id={e.id} data-name="declined"  data-idx={idx} onClick={onChangeIcon}><FontAwesomeIcon size="lg" icon={e.declined ? faThumbsDown : faThumbsUp}   /></td>
            :
            <td data-id={e.id} data-name="confirmed" data-idx={idx} onClick={onChangeIcon}><FontAwesomeIcon size="lg" icon={faQuestion}   /></td>
            }
            <td><input type="text"     onChange={onChange} name="name"       data-id={e.id} value={e.name}        data-idx={idx} /></td>
            <td><input type="text"     onChange={onChange} name="alias"      data-id={e.id} value={e.alias}       data-idx={idx} /></td>
            <td><input type="text"     onChange={onChange} name="phone"      data-id={e.id} value={e.phone}       data-idx={idx} /></td>
            <td>
              <select                  onChange={onChange} name="group"      data-id={e.id} value={e.group}       data-idx={idx}>
                  {guest_groups.map(e=>(
                      <option key={e} value={e}>{e}</option>
                  ))}
              </select>
            </td>
            <td><input type="number"   onChange={onChange} name="num_guests" data-id={e.id} value={e.num_guests}  data-idx={idx} /></td>
            <td><input type="number"   onChange={onChange} name="max_guests" data-id={e.id} value={e.max_guests}  data-idx={idx} /></td>
            <td data-id={e.id} data-name="bus"       data-idx={idx} onClick={onChangeIcon}><FontAwesomeIcon size="lg" icon={e.bus     ? faCheckCircle : faTimesCircle}   /></td>
            {filters.show_choices ? 
            <td data-id={e.id} data-name="can_bus"   data-idx={idx} onClick={onChangeIcon}><FontAwesomeIcon size="lg" icon={e.can_bus ? faCheckCircle : faTimesCircle}   /></td>
            : null }
            <td data-id={e.id} data-name="hotel"     data-idx={idx} onClick={onChangeIcon}><FontAwesomeIcon size="lg" icon={e.hotel   ? faCheckCircle :  faTimesCircle}  /></td>
            {filters.show_choices ? 
            <td data-id={e.id} data-name="can_hotel" data-idx={idx} onClick={onChangeIcon}><FontAwesomeIcon size="lg" icon={e.can_hotel ? faCheckCircle : faTimesCircle} /></td>
            : null }
            <td data-id={e.id} data-name="language"  data-idx={idx} onClick={onChangeIcon}><FontAwesomeIcon size="lg" icon={e.language === "cat" ? faCat : faChessRook}      /></td>
            {filters.show_choices ? 
            <td data-id={e.id} data-name="nochild"   data-idx={idx} onClick={onChangeIcon}><FontAwesomeIcon size="lg" icon={e.nochild ? faChild : faTimesCircle}      /></td>
            : null }
            {navigator.share ?
             <td onClick={shareIt} data-id={e.id} data-name="share" data-idx={idx}><FontAwesomeIcon size="lg" icon={faShareSquare}   /></td>
              : 
             <td data-id={e.id} data-name="share" data-idx={idx}><Link to={`/u1/${e.short_id}`}><FontAwesomeIcon size="lg" icon={faEye}   /></Link></td>
            }
          </tr>
          ))}
        </tbody>
    </table>
     )
}