import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import RsvpPage from '../../views/invitation/main';
import FirstUpdatePage from '../../views/update1/main';
import PhotosPage from '../../views/photos/main';
import {PublicPage} from '../../views/public/index';
import AdminPage from '../../views/admin/AdminPage';
import LoginPage from '../../views/login';

import '../../main-component/App/App.css';


export const App = () => {
  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path='/' component={PublicPage} />
            <Route path='/i/:inv_id' component={RsvpPage} />
            <Route path='/u1/:inv_id' component={FirstUpdatePage} />
            <Route path='/photos/:inv_id' component={PhotosPage} />
            <Route path='/admin' component={AdminPage} />
            <Route path='/login' component={LoginPage} />
          </Switch>
      </Router>
      
    </div>
  );
}

export default App;
